<template>

  <div class="horiz-box">
    <div class="avatar alt"></div>
  </div>

  <div class="fancy-box bio">
    <p>
      I am a bay area artist whose medium more than anything is systems.
      With a background in math and computer science, I've undertaken a wandering journey
      making proofs, apps, chainmail, lighting, games, and myriad other creative endeavors.
      Even though I arose from the milieu of the purely digital and infinitely scalable,
      I've developed a magnetic attraction to that which can’t be perfectly captured in 1s and 0s
      and has a personal touch rather than mass reproducibility.
    </p>
    <p>
      Check out the <a href="/projects">projects I'm working on</a> or if you want more detail, feel free to read <a href="/blog">my old posts</a>. And if you want to read future posts sign up for the mailing list below.
    </p>
    <p>
      To contact me directly, email <a href="mailto:adrian@marplebot.com">adrian@marplebot.com</a>
    </p>
  </div>

  <div class="horiz-box"></div>
  <div class="horiz-box"></div>
  <Signup/>
</template>

<script>
import Signup from "@/components/Signup"

export default {
  name: 'Home',
  components: { Signup },
}
</script>

<style>
.bio {
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 2.5em;
}
.bio a {
  text-decoration: underline;
}
</style>
