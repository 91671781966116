<template>
  <meta property="og:title" content="Marplebot links">
  <meta property="og:image" content="/images/Marplebot logo.png">

  <div class="horiz-box">
    <div class="avatar">
    </div>
  </div>

  <div class="horiz-box">
    <div class="name">
      @marplebot
    </div>
  </div>

<Signup/>

  <div class="horiz-box"></div>
  <a class="link-button fancy-box" href="https://store.steampowered.com/search/?developer=Adrian%20Marple">
    <img class="icon" src="@/assets/steam_white.svg">
    All Steam Games
  </a>
  <div class="horiz-box"></div>
  <a class="link-button fancy-box" href="https://marplebot.com/blog">
    <img class="icon" src="@/assets/link.svg">
    Blog
  </a>
  <div class="horiz-box"></div>
  <a class="link-button fancy-box" href="https://docs.google.com/spreadsheets/d/1leWTjufwvBbTNnzCfLGW247Aow3-J2M612PTLKB62cc/edit?usp=sharing">
    <img class="icon" src="@/assets/sheets_white.svg">
    Steam Curator Automation
  </a>
  <div class="horiz-box"></div>
  <a class="link-button fancy-box" href="https://www.instagram.com/adrianmarple">
    <img class="icon" src="@/assets/instagram_white.svg">
    Instagram
  </a>
  <div class="horiz-box"></div>
  <a class="link-button fancy-box" href="https://twitter.com/marplebot">
    <img class="icon" src="@/assets/twitter_white.svg">
    Twitter
  </a>
  <div class="horiz-box"></div>
  <div class="horiz-box"></div>
</template>

<script>

import Signup from "../components/Signup"

export default {
  name: 'Links',
  components: { Signup },
  mounted() {
    this.$root.isLink = true;
  },
}
</script>

<style>
.name {
  margin-bottom: 10px;
  line-height: 20px;
  margin: 0 auto 20px;
  font-size: 2em;
  display: flex;
  padding: 8px;
}

.link-button {
  position: relative;
  font-size: 2em;
  padding: 15px 20px;
  box-sizing: border-box;
  text-align: center;
  transition: color .5s cubic-bezier(.08,.59,.29,.99),
    background-color .5s cubic-bezier(.08,.59,.29,.99);
}
.link-button:hover {
  background-color: rgba(0,0,0,0.7);
  color: white;
}

.link-button .icon {
  position: absolute;
  left: 24px;
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
}
</style>
