export default [
  {
    name: "announcing-super-rhomberman",
    title: "Announcing the name of my post-Obversion interlude project",
    date: "MARCH 31, 2020",
    cover: "orbitron-proto.jpeg",
  },
  {
    name: "new-movement-system-in-obversion",
    title: "New Movement System in Obversion!",
    date: "MAY 4, 2020",
    cover: "obversion-automove.gif",
  },
  {
    name: "next-game-sneak-peek",
    title: "Next Game Sneak Peek",
    date: "JUNE 9, 2020",
    cover: "metasweeper-prototype.gif",
  },
  {
    name: "metasweeper-progress-report",
    title: "Metasweeper Progress Report",
    date: "JUNE 23, 2020",
    cover: "metasweeper-prototype-overview.png",
  },
  {
    name: "making-metasweeper-a-true-zelda-like",
    title: "Making Metasweeper a True Zelda-like",
    date: "JULY 7, 2020",
    cover: "zelda-overworld.jpeg",
  },
  {
    name: "blinky-doors-and-firefly-glyphs",
    title: "Blinky Doors and Firefly Glyphs",
    date: "JULY 21, 2020",
    cover: "metasweeper-glyphs.gif",
  },
  {
    name: "new-metasweeper-terrain-system",
    title: "New Metasweeper Terrain System",
    date: "AUGUST 4, 2020",
    cover: "metasweeper-proc-trees.gif",
  },
  {
    name: "seredipity-in-metasweeper",
    title: "Seredipity in Metasweeper",
    date: "AUGUST 18, 2020",
    cover: "electric-snake.gif",
  },
  {
    name: "new-biome",
    title: "New Biome",
    date: "SEPTEMBER 1, 2020",
    cover: "metasweeper-desert.gif",
  },
  {
    name: "supersaturated-color",
    title: "Supersaturated Color",
    date: "SEPTEMBER 15, 2020",
    cover: "metasweeper-mana.gif",
  },
  {
    name: "the-timeless-way-of-building",
    title: "The Timeless Way of Building",
    date: "SEPTEMBER 29, 2020",
    cover: "metasweeper-totsl.png",
  },
  {
    name: "new-slightly-op-abilities",
    title: "New Slightly OP Abilities",
    date: "OCTOBER 13, 2020",
    cover: "metasweeper-dash.gif",
  },
  {
    name: "cooperative-chess",
    title: "Cooperative Chess",
    date: "OCTOBER 27, 2020",
    cover: "coop-chess-alpha.png",
  },
  {
    name: "cant-stop-metasweeping",
    title: "Can't Stop Metasweeping",
    date: "JANUARY 5, 2021",
    cover: "metasweeper-electric-trees.gif",
  },
  {
    name: "creature-taxonomy",
    title: "Creature Taxonomy",
    date: "FEBRUARY 23, 2021",
    cover: "metasweeper-snek-bridge.gif",
  },
  {
    name: "the-rumspringa-plan",
    title: "The Rumspringa Plan",
    date: "MAY 4, 2021",
    cover: "rumspringa.jpeg",
  },
  {
    name: "cooperative-chess-returns",
    title: `Cooperative Chess Returns`,
    date: "JUNE 8, 2021",
    cover: "coop-chess-cover.png",
  },
  {
    name: "metasweepers-got-a-coming-soon-page-now",
    title: `Metasweeper's got a "Coming Soon" page now!`,
    date: "JULY 13, 2021",
    cover: "metasweeper-cover.png",
  },
  {
    name: "super-rhomberman-the-marplebot-and-more",
    title: "Super Rhomberman, The Marplebox, and more!",
    date: "SEPTEMBER 28, 2021",
    cover: "Rhombination.jpeg",
  },
  {
    name: "new-name-and-a-public-appearance",
    title: "New Name and a Public Appearance",
    date: "MARCH 15, 2022",
    cover: "super-orbitron-housing.jpg",
  },
  {
    name: "obritron-at-5000-watts",
    title: "Obritron at 5000 Watts + A New Mystery Game",
    date: "NOVEMBER 16, 2022",
    cover: "wallitron-progress.gif",
  },
  {
    name: "the-rise-of-the-chess-like",
    title: "The Rise of the Chess-like",
    date: "JANUARY 24, 2023",
    cover: "chess-rising.webp",
  },
  {
    name: "cornhole-rpg-print-and-play",
    title: "Cornhole RPG Print and Play",
    date: "JUNE 6, 2023",
    cover: "cornhole-rpg.png",
  },
  {
    name: "simple-vs-crunchy",
    title: "Simple vs Crunchy Mode",
    date: "NOVEMBER 1, 2023",
    cover: "cornholeRPG-choice.png",
  },
  {
    name: "tetra-tactics",
    title: "Card Craft's New Name + 1.0 Launch",
    date: "APRIL 9, 2024",
    cover: "tetra-tactics.png",
  },
  {
    name: "lumatron",
    title: "The New and Much Improved Super Orbitron",
    date: "JUNE 24, 2024",
    cover: "lumatron-logo.png",
    coverStyle: "contain",
  },
]
