<template>
  <div class="horiz-box flex-start">
    <h1 class="title">
      The Marplebot Blog
    </h1>
  </div>

  <div v-for="post in posts" class="post-wrapper">
    <a :href="'/' + post.name">
      <div class="fancy-box">
        <div class="image" :class="{ contain: post.coverStyle == 'contain'}">
          <img :src="require('@/posts/images/' + post.cover)">
        </div>
        <div>
          <h1 class="title">
            {{post.title}}
          </h1>
          <div class='date'>{{post.date}}</div>
        </div>
      </div>
    </a>

    <div class="horiz-box"></div>
  </div>

  <div class="horiz-box"></div>
  <div class="horiz-box"></div>
  <Signup/>
</template>

<script>

import Signup from "@/components/Signup"
import posts from "@/posts/posts"

export default {
  name: 'Blog',
  components: { Signup },
  data() {
    return {
      posts: posts.reverse(),
    }
  },
}
</script>

<style>
.post-wrapper {
  width: 100%
}
.post-wrapper .fancy-box {
  padding: 12px 24px;
  box-sizing: border-box;
  display: flex;
}
.image {
  padding-right: 12px;
}
.image img {
  padding: 0;
  width: 190px;
  height: 140px;
  object-fit: cover;
  object-position: top;
}
.image.contain img {
  object-fit: contain;
  object-position: center;
}
.post-wrapper h1 {
  font-size: 3em;
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .post-wrapper .fancy-box  {
    flex-direction: column;
  }
  .image {
    padding-right: 0;
  }
  .image img {
    width: 100%;
    height: auto;
    max-height: 234px;
  }
}
</style>
