<template>
  <a :href="href">
  <div class="icon">
    <svg width=64 height=64>
      <mask :id="imagePrefix" x="0" y="0" width=64 height=64>
        <rect x=0 y=0 width=64 height=64 fill="white"></rect>
        <image x=12 y=12 width="40" height="40" :href="imagePrefix+'_black.svg'"></image>
      </mask>
      <rect class="main-rect"
            width=64
            height=64
            :mask="`url(#${imagePrefix})`"
            fill="var(--bg-color)">
      </rect>

      <image x=12 y=12 width="40" height="40" :href="imagePrefix+'_white.svg'" fill="white"></image>
    </svg>
  </div>
  </a>
</template>

<script>
  
export default {
  name: "Icon",
  props: {
    href: String,
    imagePrefix: String,
  },
}
</script>

<style>
.icon {
  width: 64px;
  height: 64px;
  position: relative;
}
.icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid var(--bg-color);
}

svg > image {
  opacity: 0;
  transition: opacity .5s cubic-bezier(.08,.59,.29,.99),;
}

.icon:hover svg > image  {
  opacity: 0.5;
}

</style>