<template>
  <div class="header" v-if="post">
    <div class="horiz-box flex-start">
      <h1 class="title">
        {{post.title}}
      </h1>
    </div>
    <div class="horiz-box flex-start">
      <div class='date'>{{post.date}}</div>
    </div>


    <div class="horiz-box">
      <div class="avatar"></div>
    </div>
    <div class="horiz-box"></div>
  </div>

  <div class="post-box">
    <div class="black-box"></div>
    <div class="post-box2">
      <div class="horiz-box"></div>
      <component :is="comp"></component>
      <div class="horiz-box"></div>
    </div>
    <div class="black-box"></div>
  </div>

  <div class="horiz-box"></div>

  <div id="post-nav" v-if="post">
    <div class="previous">
      <a v-if="previousPost" :href="'/' + previousPost.name">
        <img src="@/assets/chevronleft.svg" class="chevron">
        <div >{{previousPost.title}}</div>
      </a>
    </div>
    <div class="next">
      <a v-if="nextPost" :href="'/' + nextPost.name">
        <div>{{nextPost.title}}</div>
        <img src="@/assets/chevronright.svg" class="chevron">
      </a>
    </div>
  </div>

  <div class="horiz-box"></div>
  <div class="horiz-box"></div>
  <div class="horiz-box"></div>

  <Signup v-if="post"/>
</template>

<script>

import {defineAsyncComponent} from "vue";
import Signup from "@/components/Signup"
import posts from "@/posts/posts"
import fourOhFour from "./404"

export default {
  name: 'Post',
  components: { Signup },
  data() {
    return {
    }
  },
  computed: {
    postIndex() {
      for (let index= 0; index < posts.length; index++) {
        if (posts[index].name == this.$route.params.name) {
          return index
        }
      }
      return -1
    },
    post() {
      if (this.postIndex < 0) {
        return null
      } else {
        return posts[this.postIndex]
      }
    },
    previousPost() {
      if (this.postIndex <= 0) {
        return null
      } else {
        return posts[this.postIndex - 1]
      }
    },
    nextPost() {
      if (this.postIndex >= posts.length) {
        return null
      } else {
        return posts[this.postIndex + 1]
      }
    },

    comp() {
      if (!this.post) return fourOhFour

      return defineAsyncComponent(() => import(`@/posts/${this.post.name}.vue`))
    },
  },
  methods: {
  }
}
</script>

<style>

.header {
  width: 100%;
}
.flex-start {
  justify-content: flex-start !important;
}


.date {
  color: #777;
  font-size: 16px;
  font-weight: bold;
}

.post-box {
  margin: 2px;
  width: calc(100% - 4px);
  color: #eee;
  font-size: 2.5em;
  font-family: "IMB Plex Sans", sans-serif;
  font-weight: normal;
  line-height: 1.4em;
  box-sizing: border-box;
  display: flex;
}
.post-box > .black-box {
  width: 16px;
}
.post-box2 {
  width: calc(100% - 32px);
}
.post-box2 > * {
  background-color: var(--bg-color);
  margin: 0;
}
.post-box2 > img,
.post-box2 > video  {
  width: calc(80% + 1px);
  padding: 12px 10%;
  margin-bottom: -8px;
}

.post-box p {
  background-color: var(--bg-color);
  padding: 12px 0;
}

.post-box p > a {
  text-decoration: underline;
}

.fancy-divider {
  width: 100%;
  height: 10px;
  background-color: transparent !important;
  border: 16px solid var(--bg-color);
  border-top-width: 4px;
  border-bottom-width: 4px;
  box-sizing: border-box;
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */ 
  width: 100%;
  box-sizing: border-box;
}
.video_wrapper > iframe {
  position: absolute;
}


.image-pair {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
}
.image-pair img {
  display: block;
  width: 300px;
  object-fit: scale-down;
  max-width: 50%;
}

#post-nav {
  background-color: var(--bg-color);
  display: flex;
  justify-content: space-between;
  font-size: 2.5em;
  width: 100%;
}
.previous, .next {
  width: 45%;
  display: flex;
  align-items: center;
}
.previous > a, .next > a {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.next {
  justify-content: flex-end;
  text-align: right;
}
.chevron {
  height: 32px;
}
</style>
