import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Links from './routes/Links.vue'
import Home from './routes/Home.vue'
import Post from './routes/Post.vue'
import Blog from './routes/Blog.vue'
import Games from './routes/Games.vue'
import Orbitron from './routes/Orbitron.vue'


const routes = [
  {
    path: '/link:junk?',
    component: Links,
  },
  { path: '/', component: Home },
  { path: '/blog', component: Blog },
  { path: '/projects', component: Games },
  { path: '/superorbitron', component: Orbitron },
  { path: '/:name', component: Post },
  { path: '/:name(.*)', component: Post },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const app = createApp(App)
app.use(router)
app.mount('#app')
