<template>
  <div class="horiz-box flex-start">
    <h1 class="title">
      Super Orbitron
    </h1>
  </div>
  <div class="horiz-box">
    <video controls autoplay loop muted>
      <source src="@/assets/orbitron-trailer.mp4" type="video/mp4">
    </video>
  </div>

  <div class="horiz-box"></div>
  <div class="horiz-box"></div>
  <Signup message="Sign up to learn where Super Orbitron will appear in the future!"/>
</template>

<script>
import Signup from "@/components/Signup"

export default {
  name: 'Orbitron',
  components: { Signup },
}
</script>

<style>

</style>
